// import React from "react"
import React, { useEffect } from 'react'
import {graphql, StaticQuery} from 'gatsby'
import { Link } from "gatsby"
import Layout from '../components/Layout'


if (typeof window === 'undefined') {
  global.window = {}
}


const mutetoggle = (element) => {
	var element = document.getElementById("mute-icon");
	var showreel = document.getElementById("showreel");
	element.classList.toggle("active");
	if (showreel.muted === true) {
		showreel.muted = false;
	}
	else if (showreel.muted === false) {
	showreel.muted = true;
	}
};


// window.addEventListener('scroll', handleScroll);
let count = 0;
let portfolio_total = 0;

const IndexPage = () => (
  <Layout>
	<nav className="main">
	<div className="grid-container">
	  <div className="grid-x grid-margin-x align-middle">
	    <div className="cell small-4 navbar-about">
		    <a href='#about' class='about-toggle'> About </a>
	    </div>
	    <div className="cell small-4 navbar-logo text-center">
	      <Link to="/" className="logo-link">
	        <span className="logo-name">
	          Benjamin Caron
	        </span>
	      </Link>
	    </div>
	    <div className="cell small-4 navbar-end text-right ">
	      <a
	        className="social"
	        href="https://www.instagram.com/benjamincaron/"
	        target="_blank"
	        rel="noopener noreferrer"
	      >
	        <svg xmlns="http://www.w3.org/2000/svg" fill="var(--primary-colour)" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
	      </a>
	    </div>
	  </div>
	</div>
	</nav>
    <StaticQuery query={graphql`
	{
	  allWordpressPage(filter: {wordpress_id: {eq: 2}}) {
	    edges {
	      node {
	        id
	        title
	        acf {
	          custom_description
	          custom_title
	          intro
		      intro_video {
		        source_url
		      }
		      intro_mobile {
		        source_url
		      }
	          cv_1
	          cv_2
	          lead
	          text
	          cta_text_1
	          cta_text_2
	          cta_text_3
	          link_1
	          link_2
	          link_3
	          logo_1 {
	            localFile {
	              childImageSharp {
	                fluid(maxWidth: 400) {
	                  src
	                  srcWebp
	                }
	              }
	            }
	          }
	          logo_2 {
	            localFile {
	              childImageSharp {
	                fluid(maxWidth: 400) {
	                  src
	                  srcWebp
	                }
	              }
	            }
	          }
	          logo_3 {
	            localFile {
	              childImageSharp {
	                fluid(maxWidth: 400) {
	                  src
	                  srcWebp
	                }
	              }
	            }
	          }
	        }
	      }
	    }
	  }
  	  allWordpressPost(sort: {fields: date, order: DESC}) {
	    edges {
	      node {
	        slug
	        title
	        acf {
	          metadata
	          video_background {
	            source_url
	          }
	        }
	      }
	    }
	  }
	}
	`} 
    render={props => (
        <>
        <div className='frontpageslider'>
          {props.allWordpressPage.edges.map(page=> (
			<div className='single-work' key={page.node.id}>
				<div className='slide-inner'>
					<div className='slide-content-inner' >
						<div className='text-container' style={{display: "none"}}>
							<div className='grid-container'>
								<div className='grid-x align-middle align-center notation underline'>
									<div 
									className='cell small-12 medium-8 large-7 text-center aos-init aos-animate'
									data-sal="slide-up"
									data-sal-delay="300"
									data-sal-duration="2000"
									
									>
				                      <p className='lead' dangerouslySetInnerHTML={{ __html: page.node.acf.intro }} />
									</div>
								</div>
							</div>
						</div>
						<div className='mask' style={{display: "none"}}/>
						<div 
						className='video-container medium-up'
						data-sal="fade"
						data-sal-delay="0"
						data-sal-duration="5000"
					    dangerouslySetInnerHTML={{
					        __html: `
					        <video
					          loop
					          muted
					          autoplay
					          playsinline
					          preload="metadata"
							  id="showreel"
							  class="contain"
					        >
					        <source src="${page.node.acf.intro_video.source_url}" type="video/mp4" />
					        </video>`
					      }}
					    />
						<div 
						className='video-container small-only'
						data-sal="fade"
						data-sal-delay="0"
						data-sal-duration="5000"
					    dangerouslySetInnerHTML={{
					        __html: `
					        <video
					          loop
					          muted
					          autoplay
					          playsinline
					          preload="metadata"
							  id="showreel"
							  class="contain"
					        >
					        <source src="${page.node.acf.intro_mobile.source_url}" type="video/mp4" />
					        </video>`
					      }}
					    />
						<a 
						className='down' 
						href='#portfolio_0'
						><svg data-sal="slide-up" data-sal-delay="3500" data-sal-duration="500" xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='var(--white)' viewBox='0 0 24 24'><path d='M12 21l-12-18h24z'/></svg></a>
						<a 
						id="mute-icon"
						className='mute' 
						href='#audio'
						onClick={mutetoggle}
						>
							<span class='mute-off'>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='var(--white)'><path d="M6 7l8-5v20l-8-5v-10zm-6 10h4v-10h-4v10zm20.264-13.264l-1.497 1.497c1.847 1.783 2.983 4.157 2.983 6.767 0 2.61-1.135 4.984-2.983 6.766l1.498 1.498c2.305-2.153 3.735-5.055 3.735-8.264s-1.43-6.11-3.736-8.264zm-.489 8.264c0-2.084-.915-3.967-2.384-5.391l-1.503 1.503c1.011 1.049 1.637 2.401 1.637 3.888 0 1.488-.623 2.841-1.634 3.891l1.503 1.503c1.468-1.424 2.381-3.309 2.381-5.394z"/></svg>
							</span>
							<span class='mute-on'>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='var(--white)'><path d="M5 17h-5v-10h5v10zm2-10v10l9 5v-20l-9 5zm15.324 4.993l1.646-1.659-1.324-1.324-1.651 1.67-1.665-1.648-1.316 1.318 1.67 1.657-1.65 1.669 1.318 1.317 1.658-1.672 1.666 1.653 1.324-1.325-1.676-1.656z"/></svg>
							</span>
						</a>

					</div>
				</div>
			</div>	      
          ))}
          {props.allWordpressPost.edges.map((work,i)=> (

			<div className='single-work portfolio' id={'portfolio_'+ i} key={work.node.id} data-next={portfolio_total=i+1}>
				<div className='slide-inner'>
					<div className='slide-content-inner'>
						<a 
						className='down' 
						href={'#portfolio_'+ (i + 1)}
						><svg data-sal="slide-up" data-sal-delay="3500" data-sal-duration="500" xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='var(--white)' viewBox='0 0 24 24'><path d='M12 21l-12-18h24z'/></svg></a>
						<div className='text-container'>
							<div className='grid-container'>
								<div className='grid-x align-middle align-center notation underline'>
									<div 
									className='info-inner cell small-12 medium-6 large-4 text-center'
									data-sal="slide-up"
									data-sal-delay="300"
									data-sal-duration="1000"
									
									>
				                      <p className='title' dangerouslySetInnerHTML={{ __html: work.node.title }} />
				                      <p dangerouslySetInnerHTML={{ __html: work.node.acf.metadata }} />
				                      <a href={ 'work/'+work.node.slug }>Details</a>
									</div>
								</div>
							</div>
						</div>
						<div className='mask'/>
						<div className='caron-border t'/>
						<div className='caron-border r'/>
						<div className='caron-border b'/>
						<div className='caron-border l'/>
						<div 
						className='video-container'
					    dangerouslySetInnerHTML={{
					        __html: `
					        <video
					          loop
					          muted
					          autoplay
					          playsinline
					          preload="metadata"
					        >
					        <source src="${work.node.acf.video_background.source_url}" type="video/mp4" />
					        </video>`
					      }}
					    />

					</div>
				</div>
			</div>
          ))}
          </div>
          {props.allWordpressPage.edges.map(page=> (
              <div id={"portfolio_"+portfolio_total} class='infomation' key={page.node.id} >
              <div 
              id='about' 
              className='about-container grid-container'
              >
                <div data-sal="slide-up" data-sal-duration="1000" className='grid-x grid-margin-x grid-margin-y'>
		            <div 
					className='cell small-12 medium-5 sticky' 
					>
	                    <div className='lead'>
	                      <p dangerouslySetInnerHTML={{ __html: page.node.acf.lead }} />
	                    </div>
	                    <div className='text' >
	                      <div dangerouslySetInnerHTML={{ __html: page.node.acf.text }} />
	                    </div>
                </div>
				<div 
				className='cell small-12 medium-7 large-6 large-offset-1'
				>
                    <div 
                    className='grid-x grid-margin-x grid-margin-y'
                    >
                      <div 
                      className='cell small-12 medium-6'
                      dangerouslySetInnerHTML={{ __html: page.node.acf.cv_1 }}
                      />
                      <div 
                      className='cell small-12 medium-6'
                      dangerouslySetInnerHTML={{ __html: page.node.acf.cv_2 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='contact-container grid-container'>
                <div className='grid-x align-center grid-padding-y grid-margin-x grid-margin-y' data-sal="slide-up" data-sal-duration="1000">
                  <div className='cell small-10 large-4'>
                		<div className='grid-x grid-margin-x grid-margin-y'>
                  			<div className='cell small-6 medium-4 large-3'>
                  				<a href={page.node.acf.link_1} target='_blank'>
									<img src={page.node.acf.logo_1.localFile.childImageSharp.fluid.src} />
                  				</a>
                  			</div>
                  			<div className='cell small-12 medium-7 medium-offset-1' dangerouslySetInnerHTML={{__html:page.node.acf.cta_text_1}}>
                  			</div>
                		</div>
                  </div>
                  <div className='cell small-10 large-4'>
                		<div className='grid-x grid-margin-x grid-margin-y'>
                  			<div className='cell small-6 medium-4 large-3 large-offset-1'>
                  				<a href={page.node.acf.link_2} target='_blank'>
									<img src={page.node.acf.logo_2.localFile.childImageSharp.fluid.src} />
                  				</a>
                  			</div>
                  			<div className='cell small-12 medium-7 medium-offset-1' dangerouslySetInnerHTML={{__html:page.node.acf.cta_text_2}}>
                  			</div>
                		</div>
                  </div>
                  <div className='cell small-10 large-4'>
                		<div className='grid-x grid-margin-x grid-margin-y'>
                  			<div className='cell small-6 medium-4 large-3 large-offset-1'>
                  				<a href={page.node.acf.link_3} target='_blank'>
									<img src={page.node.acf.logo_3.localFile.childImageSharp.fluid.src} />
                  				</a>
                  			</div>
                  			<div className='cell small-12 medium-7 medium-offset-1' dangerouslySetInnerHTML={{__html:page.node.acf.cta_text_3}}>
                  			</div>
                		</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )} />
  </Layout>
)


export default () => {

	const listener = e => {

		const elements = document.querySelectorAll('.portfolio');
		Array.from(elements).forEach((element, index) => {
			var scrollTop = document.documentElement.scrollTop,
				wheight = window.innerHeight,
				elementOffset = (element.offsetTop - element.scrollTop + element.clientTop),
				distance = elementOffset - scrollTop;

		    var percentage = distance / wheight;

		    if(percentage>0 && percentage<1){
		    	var $master = 200 * percentage;
			    if($master<0){
			    	$master = 0;
			    }
			    if($master>500){
			    	$master = 500;
			    }
			    if(window.innerWidth>window.innerHeight){
					element.style.setProperty('--caronH', $master+'px');
					element.style.setProperty('--caronW', $master+'px');
				} else {
					element.style.setProperty('--caronH', '0px');
					element.style.setProperty('--caronW', '0px');
				}
		    }
		});


	};

    useEffect(() => {
	    window.addEventListener("scroll", listener);
	    return () => {
	      window.removeEventListener("scroll", listener);
	    };
    })
    return (
        IndexPage()
    )
}


